'use strict'

angular.module('cb.appointments', [])
	.controller('AppointmentsController', ["$scope", "$rootScope", "$location", "$modal", "StaffService", "NotificationService", "AppointmentService", "IpadService", "UserService", "SessionService", function ($scope, $rootScope, $location, $modal, StaffService, NotificationService, AppointmentService, IpadService, UserService, SessionService) {

		$scope.init = function () {
			$scope.appointments = [];
			$scope.sHolidayRequests = [];
			$scope.recipientList = [];
			$scope.selectedUsers = [];
			$scope.areaManager;
			$scope.Holidays;
			$scope.myFilter = "All";
			$scope.selectedStaff = null;
			$scope.showFilter = false;
			$scope.showingOtherEvents = false;
			$scope.viewAllPermission = false;
			$scope.isNotSalesManager = false;
			$scope.contractsPermission = false;
			$scope.salesPermission = false;
			$scope.accountsPermission = false;
			$scope.selectedStartDate = new Date();
			$scope.selectedEndDate = new Date();

			sortPermissions();
			getStaff();
		};

		$scope.selectDates = function (startDate, endDate) {
			$scope.selectedStartDate = startDate;
			$scope.selectedEndDate = endDate;
		}

		if ($rootScope.User && $rootScope.User.Id)
			$scope.init();

		$scope.$on('UserPopulated', () => {
			$scope.init();
		});

		function GettingHolidays() {
			var data = {
				Start: $scope.Start,
				End: $scope.End
			}
			$scope.HolidayRequestsPromise = AppointmentService.GetHolidayRequests(data);
			$scope.HolidayRequestsPromise.then(function (output) {
				var HolidayRequests = [];
				var ApprovalStatus;
				var userId = output.data.User
				angular.forEach(output.data.Requests, function (value, key) {
					if (!value.IsDeleted && value.BookerManager == userId) {
						var HolidayRequest = value;
						HolidayRequests.push(HolidayRequest);
					}
				}, HolidayRequests);
				if (HolidayRequests.length == 0) {
					$scope.Holidays = null
				} else {
					$scope.Holidays = HolidayRequests;
				};
			}, function (error) {
				NotificationService.alert('Error Retrieving Holiday Requests', 'An unexpected error occurred when trying to retrieve Holiday Requests.', 'error', {});
			});
		};

		function sortPermissions() {
			$scope.viewAllPermission = $rootScope.User.Roles.filter(r => r.Name == 'Operations Manager' || r.Name == 'System Administrator' || r.Name == 'Area Manager' || r.Name == 'Marketing' || r.Name == 'Sales Manager' || r.Name == 'Field Manager' || r.Name == 'Partnership').length > 0 ? true : false;
			$scope.isNotSalesManager = $rootScope.User.Roles.filter(r => r.Name == 'Sales Manager').length > 0 ? false : true;
			$scope.contractsPermission = $rootScope.User.Roles.filter(r => r.Name == 'Contracts' || r.Name == 'Field Manager').length > 0 ? true : false;
			$scope.salesPermission = $rootScope.User.Roles.filter(r => r.Name == 'Sales Desk' || r.Name == 'Sales Manager').length > 0 ? true : false;
			$scope.accountsPermission = $rootScope.User.Roles.filter(r => r.Name == 'Accounts').length > 0 ? true : false;
		};

		$scope.toggleFilter = function () {
			if ($scope.showFilter == true) {
				$scope.showFilter = false;
				$scope.selectedUsers = [];
				$scope.appointments = [];
				//getAppointments();
			} else {
					$scope.showFilter = true;
				$scope.appointments = [];
				$scope.refreshCalendar();
			}
		};

		function getStaff() {
			$scope.GetAllStaffPromise = StaffService.getAllStaff();
			$scope.GetAllStaffPromise.then(function (res) {
				$scope.recipient = res.data.StaffList;
				var list = [];
				angular.forEach(res.data.StaffList, function (value, key) {
					if (value.AreaManager != null) {
						if (value.AreaManager.Id == $rootScope.User.Id) {
							this.push(value);
						}
					}
				},
					$scope.recipientList);
			}, function (err) {
				NotificationService.alert('Staff List Error', 'There was an error retrieving the staff list, please try reloading the page again.', 'error', {});
			});
		};

		$scope.fetchAppointments = async function () {
			await $scope.calendar;
			//get calendar date
			$scope.Start = moment($scope.calendar.fullCalendar('getDate')).set('date', 1);
			$scope.End = moment($scope.Start).add(1, 'month')
			//figure out which appointments need to be fetched
			if ($scope.selectedUsers!= null && $scope.selectedUsers.length)
				$scope.filterAppointments();
			else if ($scope.selectedStaff == 'Everyone')
				$scope.viewAll();
			else
				getAppointments();
		}

		function getAppointments() {
			if ($rootScope.onLineFlag) {
				var data = {
					Start: $scope.Start,
					End: $scope.End
				}
				$scope.GettingAppointmentsPromise = AppointmentService.getAppointmentsForCurrentUser(data);
				$scope.GettingAppointmentsPromise.then(function (output) {
					$scope.appointments = [];
					angular.forEach(output.data.Appointments, function (value, key) {
						if (!value.IsDeleted) {
							var eventInfo = SetEventColor(value)

							// Set title of appointment
							var combinedTitle = value.Title + " " + eventInfo.ApprovalStatus + ". " + "Postcode: " + value.Postcode;
							if (combinedTitle.indexOf('Ref #') == -1 && value.ExternalRef != undefined && value.ExternalRef != null) {
								// Add order reference
								combinedTitle += ' Ref #:' + value.ExternalRef;
							}

							var appointment = {
								id: value.Id,
								title: combinedTitle,
								start: moment(value.Start).unix(),
								end: moment(value.End).unix(),
								colour: eventInfo.Color,
								url: $scope.mkiUrl(value.ObjType, value.ExternalRef),
								EventType: value.EventType,
								MaintenanceId: value.MaintenanceId,
								ExternalRef: value.ExternalRef
							};
							this.push(appointment);
						};
					}, $scope.appointments);
					$scope.refreshCalendar();
				}, function (error) {
					NotificationService.alert('Error Retrieving Appointments', 'An unexpected error occurred when trying to retrieve appointments.', 'error', {});
				});
			} else {
				$scope.GettingAppointmentsPromise = IpadService.getAppointments();
				$scope.GettingAppointmentsPromise.then(function (output) {
					$scope.IpadAppointments = [];
					angular.forEach(output, function (value, key) {
						if (!value.IsDeleted && value.EventType == 'Appointment') {
							var eventInfo = SetEventColor(value)

							// Set title of appointment
							var combinedTitle = value.Title + " " + eventInfo.ApprovalStatus + ". " + "Postcode: " + value.Postcode;
							if (combinedTitle.indexOf('Ref #') == -1 && value.ExternalRef != undefined && value.ExternalRef != null) {
								// Add order reference
								combinedTitle += ' Ref #:' + value.ExternalRef;
							}
							
							var appointment = {
								id: value.Id,
								title: combinedTitle,
								start: moment(value.Start).unix(),
								end: moment(value.End).unix(),
								colour: eventInfo.Color,
								url: $scope.mkiUrl(value.ObjType, value.ExternalRef),
								EventType: value.EventType,
								MaintenanceId: value.MaintenanceId,
								ExternalRef: value.ExternalRef
							};
							this.push(appointment);
						}
					}, $scope.IpadAppointments);
				}, function (error) {
					NotificationService.alert('Error Retrieving Appointments', 'An unexpected error occurred when trying to retrieve appointments.', 'error', {});
				});
			}
		};

		function SetEventColor(value) {
			var ApprovalStatus;
			var Color;
			switch (value.EventType) {
				case 'Holiday':
					switch (value.Approved) {
						case 0:
							ApprovalStatus = "- Pending";
							Color = '#82c4e6';
							break;
						case 1:
							ApprovalStatus = "- Rejected";
							Color = '#e74c3c';
							break;
						case 2:
							ApprovalStatus = "- Approved";
							Color = '#3168cc';
							break;
						default:
							break;
					}
					break;
				case 'PublicHoliday':
					Color = '#9299a5';
				case 'Appointment':
					switch (value.ObjType) {
						case 'Enquiry':
						case 'enquiry':
						case 'SalesOrder':
							Color = '#d598a6';
							break;
						case 'Lead Follow Up':
							Color = '#E3BC9A';
							break;
						case 'Pre-Survey':
							Color = '#20A4F3';
							break;
						case 'Survey':
							Color = '#2C5F2D';
							break;
						case 'Installation':
							Color = '#9043f7';
							break;
						case 'Service':
							Color = '#90263d'
							break;
						case 'Remake':
							Color = '#ba2642'
							break;
						case 'Courtesy':
							Color = '#ffd966';
							break;
						case 'Chargeable':
							Color = '#ff9900'
							break;
						case 'Maintenance':
							Color = '#7f6000'
							break;
						default:
							if (value.ObjType.includes('Service')) {
								Color = '#990000'
							}
							break;
					}
					ApprovalStatus = '';
					break;
				default:
					break;
			}

			return {
				ApprovalStatus: ApprovalStatus,
				Color: Color,
			};
		};

		$scope.mkiUrl = function (type, id) {
			if (id == 0)
				return null;
			if (type && type.substring(0, 8) == 'Service-')
				type = 'Service';
			switch (type) {
				case 'SalesOrder':
				case 'Pre-Survey':
				case 'Survey':
				case 'Service':
				case 'Installation':
				case 'Remake':
					return '#/sales-order/' + id;
					break;
				case 'Enquiry':
				case 'Installation':
				case 'Service':
				case 'Courtesy':
				case 'Chargeable':
				case 'Maintenance':
				case 'enquiry':
					return '#/lead-details/' + id;
				default:
					break;
			}
		};

		$scope.viewAll = function () {
			var filterList = $scope.recipient.map(rec => rec.Id);
			$scope.selectedStaff = 'Everyone';
			$scope.selectedUsers = [];
			$scope.GettingAppointmentsUsers(filterList);
		};

		$scope.refreshCalendar = async function () {
			await $scope.calendar;
			$scope.calendar.fullCalendar('removeEvents');
			$scope.calendar.fullCalendar('rerenderEvents');
			angular.forEach($scope.appointments, function (value, key) {
				var appointment = {}
				switch ($scope.myFilter) {
					case 'All':
						appointment = {
							id: value.id,
							allDay: value.allDay,
							title: value.title,
							start: value.start,
							end: value.end,
							color: value.colour,
							url: value.url,
							MaintenanceId: value.MaintenanceId
						};
						break;
					case 'Holidays':
						if (value.EventType == 'Holiday' || value.EventType == 'PublicHoliday') {
							appointment = {
								id: value.id,
								allDay: value.allDay,
								title: value.title,
								start: value.start,
								end: value.end,
								color: value.colour,
							};
						};
						break;
					case 'Appointments':
						if (value.EventType == 'Appointment') {
							appointment = {
								id: value.id,
								title: value.title,
								allDay: value.allDay,

								start: value.start,
								end: value.end,
								color: value.colour,
								url: value.url,
								MaintenanceId: value.MaintenanceId
							};
						};
						break;
				};
				$scope.calendar.fullCalendar('renderEvent', appointment, true);
				$scope.calendar.fullCalendar('rerenderEvents');
			});
		};

		$scope.$on('EditAppointment', (event, Data) => {
			if (Data.Type == 'Maintenance') {
				var data = {
					MaintenanceId: Data.Id
				};
				var modalInstance = $modal.open({
					animation: true,
					size: 'lg',
					templateUrl: 'views/modal-templates/maintenance.html',
					controller: 'SurveyMaintenanceController',
					resolve: {
						data: function () {
							return data;
						}
					}
				});
			} else {
				var modalInstance = $modal.open({
					animation: true,
					size: 'lg',
					templateUrl: 'views/modal-templates/update-appointment.html',
					controller: 'UpdateAppointmentModalCtrl',
					resolve: {
						data: function () {
							return Data.Id;
						}
					}
				});
			}

			modalInstance.result.then(function () {
				if ($scope.selectedUsers.length)
					$scope.filterAppointments();
				else if ($scope.selectedStaff == 'Everyone')
					$scope.viewAll();
				else
					getAppointments();
			});
		});

		$scope.filterAppointments = function () {
			var filterList = $scope.selectedUsers.map(rec => rec.Id);
			$scope.selectedStaff = $scope.selectedUsers.map(user => user.FirstName + ' ' + user.LastName + ', ').join('').slice(0, - 2);
			$scope.GettingAppointmentsUsers(filterList);
		};

		$scope.GettingAppointmentsUsers = function (filterList) {
			$scope.GettingAppointmentsPromise = AppointmentService.getAppointmentsForUsers({
				Users: filterList,
				Start: $scope.Start,
				End: $scope.End,
			});
			$scope.GettingAppointmentsPromise.then(function (output) {
				$scope.appointments = [];
				angular.forEach(output.data.Appointments, function (value, key) {
					var Approved;
					var Color;
					var title;
					var allDay;
					var eventInfo = SetEventColor(value)
					if ((value.EventType == 'Appointment') || (value.EventType == 'Holiday' && (value.Approved == 2 || value.Approved == 0))) {
						if (value.EventType == 'Holiday' && (value.Approved == 2)) {
							title = value.Title + ' - Approved' + ' (' + value.Booker + ')'
							Color = '#2ecc71'
							allDay = true
						} else if (value.EventType == 'Holiday' && (value.Approved == 0)) {
							title = value.Title + ' - Pending' + ' (' + value.Booker + ')'
							Color = '#82c4e6'
							allDay = true
						} else {
							Color = '#e73c68'
							title = value.Title + ". " + "Postcode: " + value.Postcode + " (" + value.Attendees.map(function (user) {
								return user.Name;
							}).join(", ") + ")";
							allDay = false
						}
						var appointment = {
							id: value.Id,
							title: title,
							start: moment(value.Start).unix(),
							end: moment(value.End).unix(),
							colour: eventInfo.Color,
							url: $scope.mkiUrl(value.ObjType, value.ExternalRef),
							EventType: value.EventType,
							MaintenanceId: value.MaintenanceId,
							Type : value.ObjType
						};
						//alert(JSON.stringify(appointment));
						// Change colour for Follow-Up.
						if (appointment.ObjType == 'Lead Follow Up')
							appointment.colour = '#e73c7f';
						this.push(appointment);
					}


				}, $scope.appointments);
				$scope.refreshCalendar();
			}, function (error) {
				NotificationService.alert('Error Retrieving Appointments', 'An unexpected error occurred when trying to retrieve appointments.', 'error', {});
			});
		};

		$scope.bookHoliday = function () {
			var check;
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'bookHolidayModal.html',
				controller: 'CreateHolidayModalCtrl',
				resolve: {
					staffList: function () {
						return $scope.recipient;
					}
				}
			});
			modalInstance.result.finally(function () {
				getAppointments();
			});
		};

		$scope.MyHolidaysRequests = function () {
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'MyHolidayRequestsModal.html',
				controller: 'MyHolidayRequestsModalCtrl',
				resolve: {
					viewAll: function () {
						return $scope.viewAllPermission;
					}
				}
			});
			modalInstance.result.finally(function () {
				getAppointments();
				GettingHolidays();
			});
		};

		$scope.createAppointment = function () {
			var selectedRecipients = [];

			if ($scope.selectedStaff == 'Everyone') {
				selectedRecipients = $scope.recipient.map(rec => rec.Id);
			} else {
				selectedRecipients = $scope.selectedUsers.map(rec => rec.Id);
			}

			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'views/modal-templates/book-appointment.html',
				controller: 'CreateAppointmentModalCtrl',
				resolve: {
					Data: function () {
						return {
							Type: null,
							ObjectID: null,
							sender: null,
							Customer: null,
							StartDate: $scope.selectedStartDate,
							EndDate: $scope.selectedEndDate,
							SelectedRecipients: selectedRecipients
						}
					}
				}
			});

			/*modalInstance.result.finally(function (result) {
				console.log("Result from popover:",result);
				getAppointments();
				$scope.init();
				$location.url('/appointments')
			});*/
			modalInstance.result.then(function () {
				if ($scope.selectedUsers.length)
					$scope.filterAppointments();
				else if ($scope.selectedStaff == 'Everyone')
					$scope.viewAll();
				else
					getAppointments();
			});
		};

		if ($location.path() == '/appointments/holiday') {
			$scope.bookHoliday();
		};

		if ($location.path() == '/appointments/create-new') {
			$scope.createAppointment();
		};

		$scope.showOtherEvents = function (type) {
			if ($scope.showingOtherEvents) {
				$scope.myFilter = 'All';
				$scope.showingOtherEvents = false;
				getAppointments();
			} else {
				$scope.showingOtherEvents = true;

				var userIds = [];
				switch (type) {
					case 'Fitter':
					case 'Sales Person':
					case 'Sales Person/Fitter':
						$scope.myFilter = 'Appointments';
						userIds = $scope.recipient.filter(user => user.Roles.filter(role => role.Name == type).length > 0).map(user => user.Id);
						break;
					case 'Holidays':
						$scope.myFilter = 'Holidays';
						userIds = $scope.recipient.map(user => user.Id);
					default:
						break;
				};

				$scope.GettingAppointmentsUsers(userIds);
			};
		};
	}])
	.controller('MyHolidayRequestsModalCtrl', ["$scope", "$modalInstance", "AppointmentService", "NotificationService", "viewAll", function ($scope, $modalInstance, AppointmentService, NotificationService, viewAll) {
		$scope.init = function () {
			$scope.ViewAll = viewAll;
			$scope.SelectedView = 'MyRequests';
			$scope.TotalOtherRequests;
			$scope.PageNumber = 1;
			$scope.Limit = 10;
			$scope.toggle($scope.SelectedView);
		};

		$scope.toggle = function (tab) {
			$scope.SelectedView = tab;
			switch (tab) {
				case 'MyRequests':
				case 'DeletedRequests':
					$scope.GettingMyHolidays();
					if ($scope.ViewAll)
						$scope.GettingAllHolidays();
					break;
				case 'TeamRequests':
					$scope.GettingAllHolidays();
					break;
				default:
					break;
			};
		};

		$scope.GettingMyHolidays = function () {
			var IsDeleted = $scope.SelectedView == 'MyRequests' ? false : true;
			$scope.GettingMyHolidaysPromise = AppointmentService.getAppointmentsForCurrentUser();
			$scope.GettingMyHolidaysPromise.then(function (output) {
				$scope.MyHolidayRequestsList = output.data.Appointments.filter(appt => appt.EventType == 'Holiday' && appt.IsDeleted == IsDeleted);
			}, function (error) {
				NotificationService.alert('Error Retrieving Your Holidays', 'An unexpected error occurred when trying to retrieve Holiday.', 'error', {});
			});
		};

		$scope.GettingAllHolidays = function () {
			$scope.GettingAllHolidaysPromise = AppointmentService.GetHolidayRequests();
			$scope.GettingAllHolidaysPromise.then(function (output) {
				$scope.OtherHolidayRequestsList = output.data.Requests.filter(appt => appt.BookerManager == output.data.User && !appt.IsDeleted);
				$scope.TotalOtherRequests = $scope.OtherHolidayRequestsList.length;
			}, function (error) {
				NotificationService.alert('Error Retrieving Holiday Requests', 'An unexpected error occurred when trying to retrieve Holiday Requests.', 'error', {});
			});
		};

		$scope.sendUpdate = function (type, item) {
			$scope.UpdateHolidayPromise = AppointmentService.UpdateHolidayRequest(item);
			$scope.UpdateHolidayPromise.then(function (output) {
				var notifyText;
				switch (type) {
					case 'allow':
						notifyText = "Holiday request has been appoved.";
						break;
					case 'reject':
						notifyText = "Holiday request has not been appoved.";
						break;
					case 'Delete':
						notifyText = "Holiday request has been deleted.";
						break;
					default:
						break;
				};
				NotificationService.alert('Request Updated', notifyText, 'success', {});
				$scope.GettingMyHolidays();
				$scope.GettingAllHolidays();
			});
		};

		$scope.Allow = function (item) {
			item.Approved = 2;
			$scope.sendUpdate('allow', item);
		};

		$scope.Reject = function (item) {
			item.Approved = 1;
			$scope.sendUpdate('reject', item);
		};

		$scope.Delete = function (item) {
			item.IsDeleted = true;
			$scope.sendUpdate("Delete", item);

		};

		$scope.Close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('CreateAppointmentModalCtrl', ["$scope", "$rootScope", "$location", "$routeParams", "$modalInstance", "NotificationService", "AppointmentService", "StaffService", "Data", function ($scope, $rootScope, $location, $routeParams, $modalInstance, NotificationService, AppointmentService, StaffService, Data) {

		$scope.init = function () {
			$scope.Data = Data;
			$scope.recipientList;
			
			$scope.objType = $routeParams.Type != null ? $routeParams.Type : Data.Type != null ? Data.Type : '';
			if ($scope.objType == 'Enquiry')
				$scope.objType = 'Sales';
			$scope.objectTypes = ['Sales', 'Lead Follow Up', 'SalesOrder', 'Installation', 'Pre-Survey', 'Survey', 'Service', 'Remake', 'Service Call', 'Courtesy', 'Chargeable', 'Maintenance'];

			if ($scope.objType == 'Pre-Survey')
				$scope.objectTypes = $scope.objectTypes.filter(type => type == 'Pre-Survey' || type == 'Survey' || type == 'Installation');
			else if ($scope.objType == 'Sales')
				$scope.objectTypes = $scope.objectTypes.filter(type => type == 'Sales' || type == 'Lead Follow Up');
			else if ($scope.objType == 'Remake')
				$scope.objectTypes = $scope.objectTypes.filter(type => type == 'Remake');
			if (Data.Customer != null && Data.Customer.slice(0, 4) === 'null') {
				$scope.appointment = {
					Title: $scope.objType + ' Appointment for ' + ($routeParams.Customer || Data.Customer.slice(5)) + ', Ref #: ' + Data.Ref,
					RelatedObjectID: Data.ObjectID,
					Ref: Data.Ref,
					Update: Data.Update,
					RemakeId: Data.RemakeId
				};
			}
			else {
				$scope.appointment = {
					Title: Data.Ref != undefined ? $scope.objType + ' Appointment for ' + ($routeParams.Customer || Data.Customer) + ', Ref #: ' + Data.Ref : "",
					RelatedObjectID: Data.ObjectID,
					Ref: Data.Ref,
					Update: Data.Update,
					RemakeId: Data.RemakeId
				};
			}
			$scope.selectedForAppointment = [];
			$scope.getStaff()
		};

		$scope.getStaff = function () {
			if ($rootScope.onLineFlag) {
				$scope.GetAllStaff2Promise = StaffService.getAllStaff();
				$scope.GetAllStaff2Promise.then(function (res) {
					$scope.recipientList = res.data.StaffList;
					if (Data.SelectedRecipient != undefined) {
						$scope.AddStaff($scope.recipientList.find(rec => rec.Id == Data.SelectedRecipient), null);
					}

					if (Data.SelectedRecipients) {
						Data.SelectedRecipients.forEach((recipient) => {
							$scope.AddStaff($scope.recipientList.find(rec => rec.Id == recipient), null);
						});
					}
					else { //if no sales person, it uses the current user instead.
						$scope.AddStaff($scope.recipientList.find(rec => rec.Id == $rootScope.User.Id), null);
					}
				}, function (err) {
					NotificationService.alert('Staff List Error', 'There was an error retrieving the staff list, please try reloading the page again.', 'error', {});
				})
			} else {
				const allstaff = IpadService.getAllStaff();
				allstaff.then(function (data) {
					$scope.recipientList = data;
					if (Data.SelectedRecipient != undefined) {
						$scope.AddStaff($scope.recipientList.find(rec => rec.Id == Data.SelectedRecipient), null);
					}
					else { //if no sales person, it uses the current user instead.
						$scope.AddStaff($scope.recipientList.find(rec => rec.Id == $rootScope.User.Id), null);
					}
				})
			}
		};

		$scope.AddStaff = function (name, index) {
			$scope.selectedForAppointment.push(name);
			$scope.recipientList.splice($scope.recipientList.indexOf(name), 1);
		};

		$scope.RemoveStaff = function (name, index) {
			$scope.recipientList.push(name);
			$scope.selectedForAppointment.splice($scope.selectedForAppointment.indexOf(name), 1);
		};

		if (Data.StartDate) {
			$scope.drp_start = moment(Data.StartDate).startOf('day').format('DD MMM YYYY, h:mm:ss a');
		}
		else {
			$scope.drp_start = moment().format('DD MMM YYYY, h:mm:ss a');
		}

		if (Data.EndDate) {
			$scope.drp_end = moment(Data.EndDate).endOf('day').format('DD MMM YYYY, h:mm:ss a');
		}
		else {
			$scope.drp_end = moment().format('DD MMM YYYY, h:mm:ss a');
		}
		
		$scope.drp_options = {
			timePicker: true,
			timePicker12Hour: false,
			opens: 'left',
			startDate: moment().startOf('hour'),
			endDate: moment().startOf('hour').add(1, 'hour'),
			format: 'DD/MM/YYYY',
			showDropdowns: true,
		}
		$scope.setType = function (type) {
			$scope.objType = type
		}

		function MakeUrl(type, id) {
			switch (type) {
				case 'SalesOrder':
				case 'Survey':
				case 'Installation':
					return '/#/sales-order/' + id;
				case 'Enquiry':
				case 'enquiry':
					return '/#/lead-details/' + id;
				default:
					break;
			}
		};

		$scope.Open = function () {
			var url = MakeUrl($routeParams.Type || Data.Type, $routeParams.ObjectID || Data.ObjectID);
			$location.url(url);
			$modalInstance.dismiss();
		};

		$scope.send = function (data, start, end) {
			if (data.Update == 1) {
				var appointment = {
					Type: $scope.objType,
					Id: data.RemakeId,
					To: start,
					From: end,
					Update: data.Update
				}
				if ($scope.objType != 'Remake')
					var promise = ($rootScope.onLineFlag) ? AppointmentService.UpdateAppointmentBooking(appointment) : IpadService.updateAppointment(appointment.map(data => { return { Id: data.Id, ObjType: data.Type, Start: data.To, End: data.From, Update: data.Update } }));

			}
			else {
				var appointment = {
					Id: data.Ref,
					Title: data.Title,
					Start: start,
					End: end,
					Notes: data.Notes,
					RelatedObjectId: data.RelatedObjectID,
					RelatedObjectType: $scope.objType,
					Attendees: $scope.selectedForAppointment,
					Update: data.Update
				};
				if (appointment.RelatedObjectType == 'Sales')
					appointment.RelatedObjectType = 'Enquiry';
				if ($scope.objType != 'Remake')
					var promise = ($rootScope.onLineFlag) ? AppointmentService.MakeAppointment(appointment) : IpadService.addAppointment(appointment);
			};

			if ($scope.objType == 'Remake') {
				$modalInstance.close(appointment)
			
			}
			else {
				promise.then(function (output) {
					NotificationService.alert('Appointment Created', 'Appointment successfully created for this customer.', 'success', {});
					output.data.Appointment.type == $scope.objType;
					$modalInstance.close(output.data.Appointment);
				
				}, function () {
					NotificationService.alert('Appointment Error', 'There was an error creating this appointment.', 'error', {});
				});
			}
		}

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])
	.controller('UpdateAppointmentModalCtrl', ["$scope", "$rootScope", "$location", "$routeParams", "$modalInstance", "NotificationService", "AppointmentService", "StaffService", "data", function ($scope, $rootScope, $location, $routeParams, $modalInstance, NotificationService, AppointmentService, StaffService, data) {

		$scope.init = function () {
			$scope.Data = data;
			$scope.drpStart = moment(data.start);
			$scope.drpEnd = data.end ? moment(data.end) : moment(data.start);
			$scope.drp_options = {
				"timePicker": true,
				"timePicker12Hour" : false,
				"opens": "left",
				"startDate": $scope.drpStart,
				"endDate": $scope.drpEnd,
				format: 'DD/MM/YYYY',
				showDropdowns: true
			};

			$scope.isUserAdmin = $rootScope.User.Roles.filter(r => r.Name == 'System Administrator' || r.Name == 'Operations Manager' || r.Name == 'Sales Manager' || r.Name == 'Field Manager' || r.Name == 'Contracts').length > 0 ? true : false
		};

		$scope.$watchCollection('[drpStart, drpEnd]', function () {
			$scope.displayStartDate = moment($scope.drpStart).format('DD/MM/YYYY @ H:mm:ss');
			$scope.displayEndDate = moment($scope.drpEnd).format('DD/MM/YYYY @ H:mm:ss');
		});

		$scope.UpdateAppointment = function () {
			if (data.title.slice(-10) != '- Approved') {
				const payload = {
					Type: data.title.substr(0, 6) == 'Remake' ? 'Remake' : 'Standard',
					Id: data.id,
					From: $scope.drpStart,
					To: $scope.drpEnd,
					FitterId: null
				}
				$scope.updatingAppointmentPromise = ($rootScope.onLineFlag) ? AppointmentService.UpdateAppointmentBooking(payload) : IpadService.updateAppointment(payload.map(data => { return { Id: data.Id, ObjType: data.Type, Start: data.To, End: data.From } }));
				$scope.updatingAppointmentPromise.then(success => {
					$modalInstance.close();
				}, err => {
					NotificationService.alert('Appointment Error', 'There was an error updating the associated appointment with this maintenance booking.', 'error', {});
				});
			}
			else {
				NotificationService.alert('Holiday already approved', 'If you wish to change this period, please click on "My Holiday Requests" and remove this holiday, followed by rebooking your holiday for the desired dates.', 'error', {});
			}

		};

		$scope.DeleteAppointment = function () {
			if (data.title.slice(-10) != '- Approved') {
				const payload = {
					Type: data.title.substr(0, 6) == 'Remake' ? 'Remake' : 'Standard',
					Id: data.id,
					From: $scope.drpStart,
					To: $scope.drpEnd,
					isDeleted: true,
					FitterId: null
				}
				$scope.updatingAppointmentPromise = ($rootScope.onLineFlag) ? AppointmentService.UpdateAppointmentBooking(payload) : IpadService.updateAppointment(payload.map(data => { return { Id: data.Id, ObjType: data.Type, Start: data.To, End: data.From, isDeleted: data.isDeleted } }));
				$scope.updatingAppointmentPromise.then(success => {
					$modalInstance.close();
				}, err => {
					NotificationService.alert('Appointment Error', 'There was an error updating the associated appointment with this maintenance booking.', 'error', {});
				});
			}
			else {
				NotificationService.alert('Holiday already approved', 'If you wish to change this period, please click on "My Holiday Requests" and remove this holiday.', 'error', {});
			}
		}

		$scope.UseUrl = function () {
			window.location.href = data.url;
			$modalInstance.close();
		};

		$scope.CheckExists = function () {
			var urlExists = true;
			if (data.url == undefined) {
				urlExists = false;
			}
			return urlExists;
		}

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])
	.controller('CreateHolidayModalCtrl', ["$rootScope", "$scope", "$modalInstance", "AppointmentService", "NotificationService", "staffList", function ($rootScope, $scope, $modalInstance, AppointmentService, NotificationService, staffList) {

		$scope.staffList = staffList;

		$scope.HolidayBooking = {
			Notes: "",
			Start: null,
			End: null,
			Approved: null,
		};
		$scope.start = moment();
		$scope.end = moment();
		$scope.drp_options = {
			opens: 'left',
			format: 'DD/MM/YYYY',
			showDropdowns: true
		};
		$scope.selectedUser = $scope.staffList.find(staff => staff.Id == $rootScope.User.Id);
		$scope.showUsersControl = $rootScope.User.IsAdmin ? true : false;

		$scope.$watch('start', function () {
			$scope.displayStartDate = moment($scope.start).format('DD/MM/YYYY');
		});
		$scope.$watch('end', function () {
			$scope.displayEndDate = moment($scope.end).format('DD/MM/YYYY');
		});

		$scope.addHolidayBooking = function () {
			var start = moment($scope.start);
			var end = moment($scope.end);

			$scope.HolidayBooking.Start = moment($scope.start).format('YYYY-MM-DD 00:00:00');
			$scope.HolidayBooking.End = moment($scope.end).format('YYYY-MM-DD 23:59:59');
			$scope.HolidayBooking.Approved = 0;
			$scope.HolidayBooking.Id = this.selectedUser.Id;
			$scope.AddingHolidayBookingPromise = AppointmentService.addHolidayBooking($scope.HolidayBooking);
			$scope.AddingHolidayBookingPromise.then(function (output) {
				NotificationService.alert('Holiday Booking Added', 'Added holiday booking successfully.', 'success', {});
				//var check = true;
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Holiday Booking Failed', 'Failed to add the holiday booking.', 'error', {});
			});
		};

		$scope.cancel = function () {
			$modalInstance.dismiss('cancel');
		};
	}])

	.filter('hideSelected', function () {
		return function (input, selectedList) {
			if (input == undefined || selectedList == undefined) {
				return input;

			} else {
				var returnArray = angular.copy(input);
				selectedList.forEach(function (current) {
					for (var i = returnArray.length - 1; i >= 0; i--) {
						if (current.Name == returnArray[i].Name)
							returnArray.splice(i, 1);
					}
				});

				return returnArray;
			}
		};
	})
	.filter('Approval', function () {
		return function (input) {
			if (input == undefined) {
				return input
			} else {
				switch (input) {
					case 0:
						return "Pending";

					case 1:
						return "Rejected";

					case 2:
						return "Approved";

					default:
						return "I Failled Fix Me";

				}
			}
		};
	})
